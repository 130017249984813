import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-v3i2',
  templateUrl: './v3i2.component.html',
  styleUrls: ['./v3i2.component.css']
})
export class V3i2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
