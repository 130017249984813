<body class="auth-theme-rashmore page-professor">
  <header class="sabbi-page-header page-header-lg">
    <div class="page-header-content conternt-center"></div>
  </header>
  <!-- ./sabbi-page-header  -->
  <div class="container">
    <div class="row">
      <div class="col-sm-4 col-md-4 pull-up-240">
        <img
          class="img-bit-round"
          alt=""
          src="assets/img/sabbi-post/v3-i3.png"
        />
        <div class="sp-blank-20"></div>
        <address class="addr_future_memb">
          <h4 class="entry-title">Volume 3 Issue 3</h4>
          <div class="address-entry">
            <p class="__adtext">December 2020</p>
            <p class="__ad-num"><b>Pages: </b>1-~~~</p>
            <!-- <p class="__ad-num"><b>Pages: </b>298-588</p> -->
            <p class="__ad-num"><b>Number of Articles:</b> 12</p>
          </div>
          <footer class="contact-info">
            <h4 class="entry-title">Download Format</h4>
            <p class="__ci_num">
              <strong>Download Full Issue (PDF): </strong>
              <span>
                <a class="isDisabled" disabled download="">
                  Download
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 38px; color: red"
                  ></i>
                </a>
              </span>
            </p>
          </footer>
        </address>
      </div>
      <div class="col-sm-8">
        <article class="profile-glimps">
          <div class="stage-content-biog">
            <h3 class="stage-title">
              Confluence Journal of Economics and Allied Sciences Volume 3 Issue
              2
            </h3>
            <u><b>EDITORIAL BOARD:</b></u
            ><br />
            <b>EDITOR-IN-CHIEF: </b>Dr Sani Idris Ahmed, <br />
            <b>EDITORS: </b>Dr Awe, Emmanuel O., Dr Nuhu Musa,<br />
            <b>BUSINESS MANAGER: </b>Alehile K. Samuel, <br />
            <b>SECRETARY: </b>Sule Abubakar<br /><br />
            <u><b>Editorial Advisers:</b></u
            ><br />
            <b>Prof. Abdulkadir, M.S.</b> - Bayero University, Kano<br />
            <b>Prof. Sallahudeen Hassan</b> - Universiti Utara, Malaysia<br />
            <b>Prof. Onah, F.E.</b> - University of Nigeria, Nsukka<br />
            <b>Prof. Dalhatu Galadanci</b> - Bayero University, Kano<br />
            <b>Prof. Ibitoye, S.J.</b> - Kogi State University, Anyigba<br />
            <b>Prof. (Mrs.) Tijjani Jibril Binta</b> - Bayero University,
            Kano<br />
            <b>Prof. Wafure, G.O.</b> - University of Abuja<br />
            <b>Prof. Njiforti, P.P.</b> - Ahmadu Bello University,
            Zaria-Nigeria<br />
            <b>Dr (Mrs.) Doki, Naomi Onyeje</b> - Benue State University,
            Makurdi<br />
            <b>Dr Joseph Okwori</b> - Federal University, Lafia-Nigeria<br />
            <b>Dr Adofu, Ilemona</b> - Federal University, Lafia<br />
            <b>Dr Mohammed Umar</b> - Federal University, Kashare,
            Gombe-Nigeria<br />
            <b>Dr Amuka, J.S.</b> - University of Nigeria, Nsukka<br />

            <br /><u><b>NOTES TO INTENDING CONTRIBUTORS:</b></u
            ><br />
            The Confluence Journal of Economics and Allied Sciences publish
            papers related to the economy and society in Africa bi-annually
            (July and December). The Journal focus on policy oriented papers
            including technical papers from economics, socio-political,
            business, agricultural economics, and historical issues, financial
            or related field that border on economics and development in
            Nigeria, Africa and the world at large.
            <a routerLink="../about">Read More</a><br />
          </div>
          <!-- /.stage-content-biog -->
        </article>
      </div>
      <!-- #main -->
    </div>
  </div>
  <section class="sabbi-section stage-halfbol stage-full_width">
    <div class="container">
      <h4 class="gimps-title">TABLE OF CONTENT / ARTICLES</h4>
      <div class="row">
        <div class="col-md-6 col-sm-9">
          <ol class="appoint-timeline2 list-unstyled">
            <li>
              <span class="year"><b>Pages:</b> 1-14</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Job Pristine Migap, Ayila Ngutsav and Jerome Andohol
                </h5>
                <div class="meta-span">
                  A Causal Analysis of Financial Inclusion, Capital Market And
                  Economic Growth in Nigeria.
                </div>
                <a
                  href="https://cjeas.org/assets/jornals/volume3/issue3/cjeas-V3I3A1.pdf"
                  download="cjeas-V3I3A1"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 15-32</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Joseph .S. Oladimeji, Sunday .K. Habila and Joseph .O. Balogun
                </h5>
                <div class="meta-span">
                  Appraisal of Institutional Performance in the Management of
                  Informal Economic Activities in Zaria.
                </div>
                <a
                  href="https://cjeas.org/assets/jornals/volume3/issue3/cjeas-V3I3A2.pdf"
                  download="cjeas-V3I3A2"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 33-45</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Musa, Nuhu</h5>
                <div class="meta-span">
                  Economic Globalization and Unemployment in Nigeria: Empirical
                  Evidence.
                </div>
                <a
                  href="https://cjeas.org/assets/jornals/volume3/issue3/cjeas-V3I3A3.pdf"
                  download="cjeas-V3I3A3"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 46-61</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Macaver, O.J., Idoko, S. A. and Ojo, F. A.
                </h5>
                <div class="meta-span">
                  Economics of Electricity Consumption, Billing and Payment
                  Systems in Igbesa-Agbara Development Area of Ogun State,
                  Nigeria.
                </div>
                <a
                  href="https://cjeas.org/assets/jornals/volume3/issue3/cjeas-V3I3A4.pdf"
                  download="cjeas-V3I3A4"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 62-81</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Akighir, David Terfa and Sule, Abubakar
                </h5>
                <div class="meta-span">
                  Microfinance Bank Channels and the Output Growth of Small and
                  Medium Enterprises (SMEs) in Nigeria.
                </div>
                <a
                  href="https://cjeas.org/assets/jornals/volume3/issue3/cjeas-V3I3A5.pdf"
                  download="cjeas-V3I3A5"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 82-95</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Samuel Mbah Adamu, Tersoo Mela and Josephine Japheth Wubon
                </h5>
                <div class="meta-span">
                  Analysing the Impact of Unemployment on Economic Development
                  in Nigeria: An Application of Autoregressive Distributed Lag
                  (ARDL) Bound Testing.
                </div>
                <a
                  href="https://cjeas.org/assets/jornals/volume3/issue3/cjeas-V3I3A6.pdf"
                  download="cjeas-V3I3A6"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
          </ol>
        </div>
        <div class="col-md-6 col-sm-9">
          <ol class="appoint-timeline2 list-unstyled">
            <li>
              <span class="year"><b>Pages:</b> 96-106</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Salihu Habib Muhammed-Gani, Odonye Joel Osekweyi, Uger Francis
                  Iorbee, AjidaniMoses Sabo and Agot Galadima James
                </h5>
                <div class="meta-span">
                  Impact of Coronavirus (Covid-19) on Socio-Economic Status of
                  Households in the Federal Capital Territory of Nigeria.
                </div>
                <a
                  href="https://cjeas.org/assets/jornals/volume3/issue3/cjeas-V3I3A7.pdf"
                  download="cjeas-V3I3A7"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 107-119</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Rilwan Omar M.</h5>
                <div class="meta-span">
                  China in Africa: Trade, Aid, Economic Engagements and Soft
                  Power Encroachments.
                </div>
                <a
                  href="https://cjeas.org/assets/jornals/volume3/issue3/cjeas-V3I3A8.pdf"
                  download="cjeas-V3I3A8"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 135-153</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Adamu Ndanusa, Muahammad K. Dahiru and Mustapha Mukhtar.
                </h5>
                <div class="meta-span">
                  Assessment of the Effects of Corona Virus Disease (Covid-19)
                  Pandemic on the Global Economy.
                </div>
                <a
                  href="https://cjeas.org/assets/jornals/volume3/issue3/cjeas-V3I3A10.pdf"
                  download="cjeas-V3I3A10"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 154-169</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Odonye Joel Osekweyi, Agot Galadima James, Uger Francis
                  Iorbee, 4 5 Ajidani Moses Sabo and Maryam Abu-Goodman
                </h5>
                <div class="meta-span">
                  Analysis of the Determinant of Private Investment in Nigeria.
                </div>
                <a
                  href="https://cjeas.org/assets/jornals/volume3/issue3/cjeas-V3I3A11.pdf"
                  download="cjeas-V3I3A11"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 170-183</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Atakpa Daniel Akoh</h5>
                <div class="meta-span">
                  Human Capital Investment and Service Sector Growth in Nigeria.
                </div>
                <a
                  href="https://cjeas.org/assets/jornals/volume3/issue3/cjeas-V3I3A12.pdf"
                  download="cjeas-V3I3A12"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <!-- /.stage-halfbol -->
</body>
