<header>
    <div class="sabbi-site-header-meta">
        <div class="site-hmsl-content text-center mt_60">
            <div class="seq seq--kawsa" id="sequence">
                <div class="seq-screen">
                    <ul class="seq-canvas">
                        <li class="seq-step1" id="step1"
                            style="background:url(assets/img/slider/slide_img.jpg);background-repeat: no-repeat;background-size: cover;">
                            <div class="bg-blend"></div>
                            <div class="seq-content">

                                <h2 class="seq-title font-2 tt_up" data-seq="">Call For Paper !!!</h2>
                                <div class="seq-meta" data-seq="">
                                    <p class="seq-meta-text">The Confluence Journal of Economics and Allied Sciences
                                        (CJEAS) is calling for paper for her Volume 3, Issue 2 August 2020 Edition.
                                        <br />
                                        <b style="color: #000;">SUBMISSION DEADELINE for the August Issue is 30th
                                            July 2020. PUBLICATION
                                            DATE: August 2020.</b></p>
                                </div>
                                <a routerLink="../currentissue" class="btn-link btn-more">Get Submission Requirements
                                    ~</a>
                            </div>
                        </li>
                        <li class="seq-step1" id="step2"
                            style="background:url(assets/img/slider/slide_img2.jpg);background-repeat: no-repeat;background-size: cover;">
                            <div class="bg-blend"></div>
                            <div class="seq-content">
                                <h2 class="seq-title font-2 tt_up" data-seq="">The Confluence Journal of Economics
                                    and Allied Sciences (CJEAS)</h2>
                                <div class="seq-meta" data-seq="">
                                    <p class="seq-meta-text">A peer reviewed Journal, related to field that border
                                        on
                                        economics and development in Nigeria, Africa and the world at large</p>
                                </div>
                                <a routerLink="../about" class="btn-link btn-more">READ MORE</a>
                            </div>
                        </li>
                        <li class="seq-step1" id="step2"
                            style="background:url(assets/img/slider/slide_img3.jpg);background-repeat: no-repeat;background-size: cover;">
                            <div class="bg-blend"></div>
                            <div class="seq-content">

                                <h2 class="seq-title font-2 tt_up" data-seq="">Our Scope</h2>
                                <div class="seq-meta" data-seq="">
                                    <p class="seq-meta-text">The Journal invites contributions from Economics,
                                        Political Science, Sociology, Business Management, Banking and Finance,
                                        Agricultural Economics, and Historical Issues ...</p>
                                </div>
                                <a routerLink="../about" class="btn-link btn-more">READ MORE</a>
                            </div>
                        </li>
                    </ul>
                    <div class="sec-navigate-wrap pos-y_center">
                        <button type="button" class="seq-next"></button>
                        <button type="button" class="seq-prev"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header><!-- /.sabbi-site-head -->

<section class="sabbbi-section home-info mt_35">
    <div class="container">
        <div class="row home-info-block-first">
            <div class="col-md-3 col-sm-6">
                <article class="sabbi-thumlinepost-card solitude-bg__x">
                    <figure class="sabbi-thumlinepost-card-figure">
                        <img src="assets/img/sabbi-post/v2-i1.png" alt="" class="img-responsive img-thumpost">
                    </figure>
                    <div class="sabbi-thumlinepost-card-meta">
                        <h2 class="meta-title ht-5">Volume 2, Issue 1 December 2019 Edition</h2>
                        <p class="meta-text">This Edition Consist of 18 Articles</p>
                        <a routerLink="../v2i1" class="btn btn-unsolemn btn-action read-more">View</a>
                    </div>
                </article>
            </div>
            <div class="col-md-3 col-sm-6">
                <article class="sabbi-thumlinepost-card solitude-bg__x">
                    <figure class="sabbi-thumlinepost-card-figure">
                        <img src="assets/img/sabbi-post/v2-i2.png" alt="" class="img-responsive img-thumpost">
                    </figure>
                    <div class="sabbi-thumlinepost-card-meta">
                        <h2 class="meta-title ht-5">Volume 2, Issue 2 December 2019 Edition</h2>
                        <p class="meta-text">This Edition Consist of 19 Articles</p>
                        <a href="../v2i2" class="btn btn-unsolemn btn-action read-more">View</a>
                    </div>
                </article>
            </div>
            <div class="col-md-3 col-sm-6">
                <article class="sabbi-thumlinepost-card solitude-bg__x">
                    <figure class="sabbi-thumlinepost-card-figure">
                        <img src="assets/img/sabbi-post/v3-i2.png" alt="" class="img-responsive img-thumpost">
                    </figure>
                    <div class="sabbi-thumlinepost-card-meta">
                        <h2 class="meta-title ht-5">Volume 3, Issue 2 August 2020 Edition</h2>
                        <p class="meta-text">This Edition Consist of 15 Articles</p>
                        <a href="../v3i2" class="btn btn-unsolemn btn-action read-more">View</a>
                    </div>
                </article>
            </div>
            <div class="col-md-3 col-sm-6">
                    <article class="sabbi-thumlinepost-card solitude-bg__x">
                        <figure class="sabbi-thumlinepost-card-figure">
                            <img src="assets/img/sabbi-post/card-1.png" alt="" class="img-responsive img-thumpost">
                        </figure>
                        <div class="sabbi-thumlinepost-card-meta">
                            <h2 class="meta-title ht-5">CJEAS</h2>
                            <a routerLink="../archives" class="btn btn-unsolemn btn-action read-more">View All</a>
                        </div>
                    </article>
                </div>

        </div>

    </div>
</section><!-- /.home-info -->


<section class="sabbi-section section-selected_publication">
    <div class="container">
        <div class="selected_pulication-wrap">
            <div class="row">
                <div class="col-sm-4">
                    <div class="entry-meta">
                        <h2 class="stage-title">About CJEAS</h2>
                        <p class="entry-meta-text">The Confluence Journal of Economics and Allied Sciences (CJEAS)
                            is a peer reviewed Journal. The Journal invites contributions from Economics, Political
                            Science, Sociology, Business Management, Banking and Finance, Agricultural Economics,
                            and Historical Issues or related field that border on economics and development in
                            Nigeria, Africa and the world at large. </p>
                        <p class="entry-meta-text">
                            <b>Current Edition: </b>Volume 3, Issue 2, August 2020 Edition
                        </p>
                        <ul class="list-unstyled entry-meta-list list-style-round_item">
                            <!-- <li>ISSN: …………. (Online),</li> -->
                            <li>ISSN: 2437-1661 (Print),</li>
                        </ul>
                        <p class="entry-meta-text">
                            <b>Guidelines for submission of Articles:</b>
                        </p>
                        <ul class="list-unstyled entry-meta-list list-style-round_item">
                            <li>CJEAS accepts articles submitted in MS Word only and written in English. Citations
                                and referencing should be APA compliant.</li>
                        </ul>
                        <div class="action-wrap"><a routerLink="../about" class="btn btn-unsolemn btn-action read-more">Read
                                More</a></div>
                    </div><!-- /.entry-meta -->
                </div>
                <aside class="col-sm-8">
                    <div class="paper_cut">
                        <aside class="stage-halfbol_content">
                            <h2 class="stage-title font-md lil-line">Call For Paper !!!</h2>
                            <p class="stage-halfbol_text">The Confluence Journal of Economics and Allied Sciences
                                (CJEAS) is calling for paper for her Volume 3, Issue 2 August 2020 Edition.
                                <br />
                                <b style="color: #000;">SUBMISSION DEADELINE for the August Issue is 30th
                                    July 2020. PUBLICATION
                                    DATE: August 2020.</b></p>
                            <a routerLink="../currentissue" class="btn btn-action btn-gules">Get Submission Requirements
                                ~</a>
                        </aside>
                    </div><!-- /.paper_cut -->
                </aside>
            </div>
        </div><!-- /.selected_pulication-wrap -->
    </div>
</section><!-- /.section-selected_publication -->
