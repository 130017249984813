// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAdaX_okWslIFjtwj1_rln8RcCnyW2bB9U",
    authDomain: "cjeas-93dce.firebaseapp.com",
    databaseURL: "https://cjeas-93dce.firebaseio.com",
    projectId: "cjeas-93dce",
    storageBucket: "cjeas-93dce.appspot.com",
    messagingSenderId: "926171354825",
    appId: "1:926171354825:web:b29217e1e6297cf9949f18",
    measurementId: "G-1PQPRMX0BM"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
