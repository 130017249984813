<body class="auth-theme-rashmore page-team">
  <header class="sabbi-page-header page-header-lg">
    <div class="page-header-content conternt-center">
      <div class="header-title-block">
        <h1 class="page-title">Editorial Board</h1>
      </div>
    </div>
  </header>
  <div class="auth-breadcrumb-wrap">
    <div class="container">
      <ol class="breadcrumb sabbi-breadcrumb">
        <li><a routerLink="">Home</a></li>
        <li class="active">Editorial Board</li>
      </ol>
    </div>
  </div><!-- /.auth-breadcrumb-wrap -->
  <main class="sabbi-page-wrap">
    <div class="container">
      <section class="bio__holder">
        <div class="row">
          <div class="col-md-4 col-sm-5">
            <div class="profile-card profile-card-meta_classic">
              <figure>
                <img class="img-responsive" src="assets/img/profile/editorinchief.png" alt="sabbi-profile-pic">

              </figure>

            </div>
          </div>
          <div class="col-md-8 col-sm-7">
            <article class="profile-glimps">
              <h2 class="entry-title title-foc-md">Dr Sani Idris Ahmed</h2>
              <p class="text-foc-md">Editor-in-Chief</p>
              <figure>
                <!-- <figcaption style="font-size: 10px;">
                  <h5 class="fig-title">Contact Information</h5>
                  <div class="fig-meta">
                    <p class="text-foc-md"><span>(+234) 806 2065 126 </span></p>
                    <p class="text-foc-md"><span>idrisas@kogistateuniversity.edu.ng</span></p>
                  </div>
                </figcaption> -->
              </figure>
              <div class="profile-card-meta">
                <span>
                  <a routerLink="../editorialboard/saniidrisahmed">
                    View Profile <sup><i class="fas fa-external-link-alt"></i></sup>
                  </a>
                </span>
              </div>
            </article>
          </div>
        </div>
      </section><!-- /.bio__holder -->
      <div class="our_team-profile-card__holder">
        <div class="row">
          <div class="col-sm-6 col-md-3">
            <div class="profile-card profile-card-meta_classic">
              <figure>
                <img class="img-responsive" src="assets/img/profile/businessmanager.png" alt="sabbi-profile-pic">
                <figcaption>
                  <h3 class="fig-title">Samuel Kehinde Alehile</h3>
                  <div class="fig-title-des"><i>Business Manager</i></div>
                  <!-- <div class="fig-meta">
                    <p class="fig-cal"><span>(+234) 806 9255 111</span></p>
                    <p class="fig-mail">
                      <span>alehile.ks@ksu.edu.ng</span></p>
                  </div> -->
                </figcaption>
              </figure>
              <div class="profile-card-meta">
                <span>
                  <a routerLink="../samuelkehindealehile">
                    View Profile <sup><i class="fas fa-external-link-alt"></i></sup>
                  </a>
                </span>
              </div>
            </div><!-- /.profile-card  .profile-card-meta_classic -->
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="profile-card profile-card-meta_classic">
              <figure>
                <img class="img-responsive" src="assets/img/profile/editor.png" alt="sabbi-profile-pic">
                <figcaption>
                  <h3 class="fig-title">Dr Awe Emmanuel O.</h3>
                  <div class="fig-title-des">Editor</div>
                  <!-- <div class="fig-meta">
                    <p class="fig-cal"><span>(+234) 806 5012 286</span></p>
                    <p class="fig-mail"><span>emmanueloa@kogistateuniversity.edu.ng</span></p>
                  </div> -->
                </figcaption>
              </figure>
              <div class="profile-card-meta">
                <span>
                  <a routerLink="../aweemmanuelo">
                    View Profile <sup><i class="fas fa-external-link-alt"></i></sup>
                  </a>
                </span>
              </div>
            </div><!-- /.profile-card  .profile-card-meta_classic -->
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="profile-card profile-card-meta_classic">
              <figure>
                <img class="img-responsive" src="assets/img/profile/editor2.png" alt="sabbi-profile-pic">
                <figcaption>
                  <h3 class="fig-title">Dr Nuhu Musa</h3>
                  <div class="fig-title-des">Editor</div>
                  <!-- <div class="fig-meta">
                    <p class="fig-cal"><span>(+234) 706 0526 595</span></p>
                    <p class="fig-mail"><span>musanuhuadams@gmail.com</span></p>
                  </div> -->
                </figcaption>
              </figure>
              <div class="profile-card-meta">
                <span>
                  <a routerLink="../nuhumusa">
                    View Profile <sup><i class="fas fa-external-link-alt"></i></sup>
                  </a>
                </span>
              </div>
            </div><!-- /.profile-card  .profile-card-meta_classic -->
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="profile-card profile-card-meta_classic">
              <figure>
                <img class="img-responsive" src="assets/img/profile/secretary.png" alt="sabbi-profile-pic">
                <figcaption>
                  <h3 class="fig-title">Sule Abubakar</h3>
                  <div class="fig-title-des">Secretary</div>
                </figcaption>
              </figure>
              <div class="profile-card-meta">
                <span>
                  <a routerLink="../suleabubakar">
                    View Profile <sup><i class="fas fa-external-link-alt"></i></sup>
                  </a>
                </span>
              </div>

            </div><!-- /.profile-card  .profile-card-meta_classic -->
          </div>

        </div>
      </div>
    </div>
    <section class="sabbi-section stage-halfbol stage-full_width">
      <div class="container">
        <h4 class="gimps-title">Editorial Advisers</h4>
        <div class="row">
          <div class="col-md-6 col-sm-9">
            <ol class="appoint-timeline  list-unstyled  ">
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Prof. Abdulkadir, M.S.</h5>
                  <div class="meta-span">Bayero University, Kano-Nigeria</div>
                </div>
              </li>
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Prof. Dalhatu Galadanci</h5>
                  <div class="meta-span">Bayero University Kano</div>
                </div>
              </li>
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Prof. Tijjani Jibril Binta</h5>
                  <div class="meta-span">Bayero University Kano</div>
                </div>
              </li>
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Prof. Wafure, J.O.</h5>
                  <div class="meta-span">University of Abuja-Nigeria</div>
                </div>
              </li>
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Dr Idoko, C.U.</h5>
                  <div class="meta-span">Prince Abubakar Audu University, Anyigba-Nigeria</div>
                </div>
              </li>
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Dr Adofu Ilemona</h5>
                  <div class="meta-span">Federal University, Lafia-Nigeria</div>
                </div>
              </li>
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Dr Joseph Okwori</h5>
                  <div class="meta-span">Federal University, Lafia-Nigeria</div>
                </div>
              </li>
            </ol>
          </div>
          <div class="col-md-6 col-sm-9">
            <ol class="appoint-timeline  list-unstyled  ">
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Prof. Sallahudeen Hassan</h5>
                  <div class="meta-span">Universiti Utara, Malaysia</div>
                </div>
              </li>
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Prof. Onah, F.E.</h5>
                  <div class="meta-span">University of Nigeria, Nsukka</div>
                </div>
              </li>
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Prof. Ibitoye, S.J.</h5>
                  <div class="meta-span">Prince Abubakar Audu University, Anyigba-Nigeria</div>
                </div>
              </li>
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Prof. Njiforti, P.P.</h5>
                  <div class="meta-span">Ahmadu Bello University, Zaria-Nigeria</div>
                </div>
              </li>
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Dr (Mrs.) Doki, N.O.</h5>
                  <div class="meta-span">Benue State University, Makurdi-Nigeria</div>
                </div>
              </li>
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Dr Mohammed Umar</h5>
                  <div class="meta-span">Federal University, Kashare, Gombe-Nigeria </div>
                </div>
              </li>
              <li>
                <span class="year"><i class="fas fa-book"></i></span>
                <div class="appoint-meta">
                  <h5 class="meta-title">Dr Amuka, J.S.</h5>
                  <div class="meta-span">University of Nigeria, Nsukka</div>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section><!-- /.stage-halfbol -->
  </main>
</body>
