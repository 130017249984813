<body class="auth-theme-rashmore page-professor">
  <header class="sabbi-page-header page-header-lg">
    <div class="page-header-content conternt-center"></div>
  </header>
  <!-- ./sabbi-page-header  -->
  <div class="container">
    <div class="row">
      <div class="col-sm-4 col-md-4 pull-up-240">
        <img
          class="img-bit-round"
          alt=""
          src="assets/img/sabbi-post/v3-i2.png"
        />
        <div class="sp-blank-20"></div>
        <address class="addr_future_memb">
          <h4 class="entry-title">Volume 3 Issue 2</h4>
          <div class="address-entry">
            <p class="__adtext">August 2020</p>
            <p class="__ad-num"><b>Pages: </b>1-217</p>
            <!-- <p class="__ad-num"><b>Pages: </b>298-588</p> -->
            <p class="__ad-num"><b>Number of Articles:</b> 15</p>
          </div>
          <footer class="contact-info">
            <h4 class="entry-title">Download Format</h4>
            <p class="__ci_num">
              <strong>Download Full Issue (PDF): </strong>
              <span>
                <a class="isDisabled" disabled download="">
                  Download
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 38px; color: red"
                  ></i>
                </a>
              </span>
            </p>
          </footer>
        </address>
      </div>
      <div class="col-sm-8">
        <article class="profile-glimps">
          <div class="stage-content-biog">
            <h3 class="stage-title">
              Confluence Journal of Economics and Allied Sciences Volume 3 Issue
              2
            </h3>
            <u><b>EDITORIAL BOARD:</b></u
            ><br />
            <b>EDITOR-IN-CHIEF: </b>Dr Sani Idris Ahmed, <br />
            <b>EDITORS: </b>Dr Awe, Emmanuel O., Dr Nuhu Musa,<br />
            <b>BUSINESS MANAGER: </b>Alehile K. Samuel, <br />
            <b>SECRETARY: </b>Sule Abubakar<br /><br />
            <u><b>Editorial Advisers:</b></u
            ><br />
            <b>Prof. Abdulkadir, M.S.</b> - Bayero University, Kano<br />
            <b>Prof. Sallahudeen Hassan</b> - Universiti Utara, Malaysia<br />
            <b>Prof. Onah, F.E.</b> - University of Nigeria, Nsukka<br />
            <b>Prof. Dalhatu Galadanci</b> - Bayero University, Kano<br />
            <b>Prof. Ibitoye, S.J.</b> - Kogi State University, Anyigba<br />
            <b>Prof. (Mrs.) Tijjani Jibril Binta</b> - Bayero University,
            Kano<br />
            <b>Prof. Wafure, G.O.</b> - University of Abuja<br />
            <b>Prof. Njiforti, P.P.</b> - Ahmadu Bello University,
            Zaria-Nigeria<br />
            <b>Dr (Mrs.) Doki, Naomi Onyeje</b> - Benue State University,
            Makurdi<br />
            <b>Dr Joseph Okwori</b> - Federal University, Lafia-Nigeria<br />
            <b>Dr Adofu, Ilemona</b> - Federal University, Lafia<br />
            <b>Dr Mohammed Umar</b> - Federal University, Kashare,
            Gombe-Nigeria<br />
            <b>Dr Amuka, J.S.</b> - University of Nigeria, Nsukka<br />

            <br /><u><b>NOTES TO INTENDING CONTRIBUTORS:</b></u
            ><br />
            The Confluence Journal of Economics and Allied Sciences publish
            papers related to the economy and society in Africa bi-annually
            (July and December). The Journal focus on policy oriented papers
            including technical papers from economics, socio-political,
            business, agricultural economics, and historical issues, financial
            or related field that border on economics and development in
            Nigeria, Africa and the world at large.
            <a routerLink="../about">Read More</a><br />
          </div>
          <!-- /.stage-content-biog -->
        </article>
      </div>
      <!-- #main -->
    </div>
  </div>
  <section class="sabbi-section stage-halfbol stage-full_width">
    <div class="container">
      <h4 class="gimps-title">TABLE OF CONTENT / ARTICLES</h4>
      <div class="row">
        <div class="col-md-6 col-sm-9">
          <ol class="appoint-timeline2 list-unstyled">
            <li>
              <span class="year"><b>Pages:</b> 1-11</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Rabiu Olowo and Mohammed Abdullahi</h5>
                <div class="meta-span">
                  Exploring the Global Management Accounting Principles (GMAP)
                  to Improve Public Sector Financial Management in Nigeria.
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A13.pdf"
                  download="cjeas-V3I2A1"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 12-23</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Adamu Ummulkhayr and Sani Idris Ahmed
                </h5>
                <div class="meta-span">
                  Determinants of Zakat and Tax Compliance in Nigeria: A
                  Comparative Analysis.
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A2.pdf"
                  download="cjeas-V3I2A2"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 24-36</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Ashkah Felix Onoriode and Ozor, Patience Lilian
                </h5>
                <div class="meta-span">
                  Human Capital Development and Economic Growth: Empirical
                  Evidence from ECOWAS Sub-region.
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A3.pdf"
                  download="cjeas-V3I2A3"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 37-45</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Kabiru Abdu Ibrahim</h5>
                <div class="meta-span">
                  Assessment of Investment Risk Tolerance of Academic Staff
                  Members in Sa'adatu Rimi College of Education Kumbotso Kano
                  State.
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A4.pdf"
                  download="cjeas-V3I2A4"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 46-57</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Waniyos, U. Hamidu, Bakari, M. Adamu, Buba, M. Soba, and
                  Adams, J. Yusuf.
                </h5>
                <div class="meta-span">
                  Empirical Analysis of Internal Generated Revenue in Adamawa
                  State Nigeria.
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A5.pdf"
                  download="cjeas-V3I2A5"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 58-72</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Rabiu Olowo and Mohammed Abdullahi Umar
                </h5>
                <div class="meta-span">
                  Impact of Financial Management processes on the Quality of
                  Governance in the Lagos State, Using the Global Management
                  Accounting Principles (GMAP) Framework
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A6.pdf"
                  download="cjeas-V3I2A6"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 73-85</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Adamu Ummulkhayr and Sani, Idris Ahmed
                </h5>
                <div class="meta-span">
                  Zakah, a Viable Tool for Poverty Reduction
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A7.pdf"
                  download="cjeas-V3I2A7"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 86-97</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Adejoh, M.O., Ameji, N.E., and Olorunfemi, O.
                </h5>
                <div class="meta-span">
                  Digital Banking and Financial Deepening in Nigeria.
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A8.pdf"
                  download="cjeas-V3I2A8"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>

          </ol>
        </div>
        <div class="col-md-6 col-sm-9">
          <ol class="appoint-timeline2 list-unstyled">
            <li>
              <span class="year"><b>Pages:</b> 98-114</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Felix Onoriade Ashakah and Osaretin Godspower Okungbowa
                </h5>
                <div class="meta-span">
                  Effect of Internationnal Trade on Economic Growth in ECOWAS
                  Sub-region.
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A9.pdf"
                  download="cjeas-V3I2A9"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 115-129</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Awe, Emmanuel O., Ugbaka, Malachy A., Abdulkadir Yerima Y.,
                  and Idoko Suzan A.
                </h5>
                <div class="meta-span">
                  Electricity Infrastructure and Economic Growth in Nigeria:
                  Impact Analysis.
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A10.pdf"
                  download="cjeas-V3I2A10"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 130-144</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Salami Nasiru Enesi, Salami Hamzat, and Issac Arome Yusuf.
                </h5>
                <div class="meta-span">
                  Assessing the Impact of Macro Environment on the Profit of
                  Deposit Money Banks in Nigeria.
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A11.pdf"
                  download="cjeas-V3I2A11"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 145-165</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Michael Baghebo and Kanu Collins Chukwuemeka</h5>
                <div class="meta-span">Monetary Policy and Banking Sector Performance in Nigeria.</div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A12.pdf"
                  download="cjeas-V3I2A12"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 166-186</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Mohammed Umar, Zulaihatu A. Zubairu, and Sani Abubakar</h5>
                <div class="meta-span">
                  Exchange Rate Stability in Nigeria (1970 - 2018): Does Inflation Differential Matter?
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A13.pdf"
                  download="cjeas-V3I2A13"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 187-205</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Koginam Owudumopu Alex and Ekiye Ebipuamere
                </h5>
                <div class="meta-span">
                  Determinants of Imports and the Nigerian Economy
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A14.pdf"
                  download="cjeas-V3I2A14"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 206-217</span>
              <div class="appoint-meta">
                <h5 class="meta-title">
                  Kajo A. E., Afipinsoro O. M., Nzelibe G. C., Isyaka M. S., and Agulanna A. N.
                </h5>
                <div class="meta-span">
                  Small and Medium-Scale Enterprice (SMEs) and Job Creation: Cross-Sectional Evidence from Bwari, FCT Nigeria
                </div>
                <a
                href="https://cjeas.org/assets/jornals/volume3/issue2/cjeas-V3I2A15.pdf"
                  download="cjeas-V3I2A15"
                  target="_blank"
                >
                  Download (pdf)
                  <i
                    class="fas fa-file-pdf-o"
                    style="font-size: 20px; color: red"
                  ></i>
                </a>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <!-- /.stage-halfbol -->
</body>
