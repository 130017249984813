<body class="auth-theme-rashmore page-professor">
    <header class="sabbi-page-header page-header-lg">
        <div class="page-header-content conternt-center"></div>
    </header><!-- ./sabbi-page-header  -->
    <div class="container">
        <div class="row">
            <div class="col-sm-4 col-md-4 pull-up-240">
                <img class="img-bit-round" alt="" src="assets/img/sabbi-post/card-1.png">
                <div class="sp-blank-20"></div>
                <address class="addr_future_memb">
                    <h4 class="entry-title">Address</h4>
                    <div class="address-entry">
                        <p class="__adtext">Department of Economics,</p>
                        <p class="__ad-num">Prince Abubakar Audu University,</p>
                        <p class="__ad-num">P.M.B. 1008,</p>
                        <p class="__ad-num">Anyigba-Nigeria</p>
                    </div>
                    <footer class="contact-info">
                        <h4 class="entry-title">Contact Information</h4>
                        <p class="__ci_num"><strong>Call:</strong> <span>+234 806 2065 126 | +234 806 5012 286</span>
                        </p>
                        <p class="__ci_num"><strong>Email:</strong> <span>cjeasksu@gmail.com</span></p>
                    </footer>
                </address>
            </div>
            <div class="col-sm-8">
                <article class="profile-glimps">
                    <div class="stage-content-biog">
                        <h3 class="stage-title">About CJEAS</h3>
                        <p class="stage-text">The Confluence Journal of Economics and Allied Sciences (CJEAS) is a peer
                            reviewed Journal. The Journal invites contributions from Economics, Political Science,
                            Sociology, Business Management, Banking and Finance, Agricultural Economics, and Historical
                            Issues or related field that border on economics and development in Nigeria, Africa and the
                            world at large.<br />

                            <b>Current Edition:</b> Volume 3, Issue 2, August 2020 Edition<br />

                            <!-- <b>ISSN:</b> …………. (Online),<br /> -->
                            <b>ISSN:</b> 2437-1661 (Print),</p>
                            <h3 class="stage-title">Guidelines for submission of Articles</h3>
                        <ul class="list-unstyled entry-meta-list list-style-round_item">
                            <li>CJEAS accepts articles submitted in MS Word only and written in English. Citations and
                                referencing should be APA compliant. </li>
                            <li>Manuscripts must be typed on <b>A4 paper, Font Size of 12, 1.5 Line Spacing and One Inch
                                    (2.5cm) for top, bottom and both side of the page.</b> All characters must be in
                                <b>TIMES
                                    NEW ROMAN.</b> Paper should not be more than <b>18 pages</b> including references. All
                                equations should be prepared using Microsoft Equation Editor or Math type.</li>
                            <li>Articles should appear in the following order: Title of Article; Name(s) of Author(s),
                                with the corresponding contact address (email and phone number); Institutional
                                Affiliation(s); Abstract; Keywords; JEL Classification code; Introduction; Literature
                                Review; Methodology; Results and Discussion; Conclusion/Recommendation; References; and
                                Appendix (if any). Abstract should not be more than <b>250 words.</b></li>
                            <li>Papers submitted after the submission deadline of an issue are automatically rolled over
                                to the following issue. </li>
                            <li>All tables and figures should be appropriately numbered, titled, bolded and placed on
                                top left of the tables or figures. In describing or explaining tables or figures,
                                authors should rather use “The results of OLS tests are presented in table 1”</li>
                            <li>All manuscript should be sent to the following <b>emails: cjeasksu@gmail.com & copy
                                    alehie.ks@ksu.edu.ng</b></li>
                        </ul>
                            <h3 class="stage-title">Submission and Assessment/Publication Fee</h3>
                            <p class="stage-text">Payment is subdivided into peer review fee of <b> NGN 5,000.00 (Five
                                    Thousand
                                    Naira only)</b> and Publication Fee of <b>NGN 20,000.00 (Twenty Thousand Naira
                                    only).</b> The peer
                                review fee is paid and evidence submitted with the manuscript while the publication fee
                                is paid upon the acceptance of the manuscript. Submission of corrected manuscript should
                                be accompanied with evidence of payment of publication fee. Authors are entitled to <b>ONE
                                    (1) Hard Copy of the Journal.</b> Extra copy attracts <b>NGN 3,000.00.</b></p>
                            <p>Author(s) can make direct cash payment or through electronic fund transfer to the detalis
                                below;</p>
                            <h3 class="stage-title">Payment Account Details</h3>
                            <ul class="list-unstyled entry-meta-list list-style-round_item">
                                <li><b>ACCOUNT NAME:</b> CONFLUENCE JOURNAL OF ECONOMICS & ALLIED SCIENCES (CJEAS)</li>
                                <li><b>ACCOUNT NUMBER: </b>1022876347 </li>
                                <li><b>BANK: </b>UNITED BANK FOR AFRICA PLC</li>
                                <li><b>ACCOUNT TYPE: </b>CURRENT</li>
                            </ul>

                    </div><!-- /.stage-content-biog -->
                </article>
                <section class="gimps-container">
                    <!-- <h4 class="gimps-title">Awards & Prizes</h4> -->
                    <div class="row">
                        <div class="col-sm-11">
                            <ul class="awards-list list-unstyled">

                            </ul>
                        </div>
                    </div>
                </section><!-- /.gimps-container -->
            </div><!-- #main -->
        </div>
    </div>
</body>
