<body class="auth-theme-rashmore page-professor">
  <header class="sabbi-page-header page-header-lg">
    <div class="page-header-content conternt-center"></div>
  </header><!-- ./sabbi-page-header  -->
  <div class="container">
    <div class="row">
      <div class="col-sm-4 col-md-4 pull-up-240">
        <img class="img-bit-round" alt="" src="assets/img/sabbi-post/v2-i2.png">
        <div class="sp-blank-20"></div>
        <address class="addr_future_memb">
          <h4 class="entry-title">Volume 2 Issue 2</h4>
          <div class="address-entry">
            <p class="__adtext">December 2019</p>
            <p class="__ad-num"><b>Pages: </b>1-291</p>
            <!-- <p class="__ad-num"><b>Pages: </b>298-588</p> -->
            <p class="__ad-num"><b>Number of Articles:</b> 19</p>
          </div>
          <footer class="contact-info">
            <h4 class="entry-title">Download Format</h4>
            <p class="__ci_num"><strong>Download Full Issue (PDF): </strong>
              <span>
                <a href="" download="">
                  Download <i class="fas fa-file-pdf-o" style="font-size:38px;color:red"></i>
                </a>
              </span>
            </p>
          </footer>
        </address>
      </div>
      <div class="col-sm-8">
        <article class="profile-glimps">
          <div class="stage-content-biog">
            <h3 class="stage-title">Confluence Journal of Economics and Allied Sciences Volume 2 Issue 2</h3>
            <u><b>BOARD OF EDITORS:</b></u><br />
            <b>EDITOR-IN-CHIEF: </b>DR IDOKO, C.U., <b>EDITOR: </b>DR MOHAMMED, IBRAHIM .D.,
            <b>BUSINESS MANAGER: </b>DR AWE, E.O., <b>SECRETARY: </b>SULE ABUBAKAR<br /><br />
            <u><b>CONSULTING EDITORS:</b></u><br />
            <b>Prof. Onah, F.E. -</b> University of Nigeria, Nsukka,<br />
            <b>Prof. Ibitoye, S.J. -</b> Kogi State University, Anyigba,<br />
            <b>Prof. Wafure, G.O. -</b> University of Abuja,<br />
            <b>Prof. (Mrs.) Tijjani Jibril Binta -</b> Bayero University, Kano,<br />
            <b>Dr Adofu, Ilemona -</b> Federal University, Lafia,<br />
            <b>Dr (Mrs.) Doki, Naomi Onyeje -</b> Benue State University, Makurdi.<br />

            <br /><u><b>NOTES TO INTENDING CONTRIBUTORS:</b></u><br />
            The Confluence Journal of Economics and Allied Sciences publish papers related to the economy and
            society in Africa bi-annually (July and December). The Journal focus on policy oriented papers
            including technical papers from economics, socio-political, business, agricultural economics, and
            historical issues, financial or related field that border on economics and development in Nigeria, Africa
            and the world at large. <a routerLink="../about">Read More</a><br />
          </div><!-- /.stage-content-biog -->
        </article>
      </div><!-- #main -->
    </div>
  </div>
  <section class="sabbi-section stage-halfbol stage-full_width">
    <div class="container">
      <h4 class="gimps-title">TABLE OF CONTENT / ARTICLES</h4>
      <div class="row">
        <div class="col-md-6 col-sm-9">
          <ol class="appoint-timeline2  list-unstyled  ">
            <li>
              <span class="year"><b>Pages:</b> 1-17</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Alfa, Yakubu</h5>
                <div class="meta-span">Long-run Impact of Interest Rate, Exchange Rate and Inflation on
                  Private Sector Investment in Nigeria.
                </div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A1.pdf" download="cjeas-V2I2A1"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 18-31</span>
              <div class="appoint-meta">
                <h5 class="meta-title"> Ominyi, S.O. (Ph.D), Sule Abubakar and Salifu, A.B.</h5>
                <div class="meta-span">Sustainable Development and Poverty Reduction Nexus: A
                  Reassessment of Nigeria’s Situation.</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A2.pdf" download="cjeas-V2I2A2"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 32-45</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Yunana, Titus Wuyah and Idakwoji, Blessing Ojochogwu</h5>
                <div class="meta-span">The Effect of External Debt on Economic Growth in Nigeria.
                </div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A3.pdf" download="cjeas-V2I2A3"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 46-61</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Musa, Nuhu (Ph.D) and Ali Maji (Ph.D)</h5>
                <div class="meta-span">Effect of Debt Servicing on Economic Growth in Nigeria (1986-
                  2018): An Autoregressive Distributed Lag Approach.</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A4.pdf" download="cjeas-V2I2A4"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 62-78</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Mohammed, Yusuf Danladi and Mohammed, Ibrahim Danlami (Ph.D)</h5>
                <div class="meta-span">Public Perceptions on Islamic Banking Along the Conventional
                  Banking System in Nigeria. </div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A5.pdf" download="cjeas-V2I2A5"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 79-96</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Joy Eleojo Ebeh, Hamzat Salami,Muhammad Ali and
                  Yakub Adavize Al-Ameen</h5>
                <div class="meta-span">The Impact of Infant Mortality on Economic Growth in Nigeria. </div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A6.pdf" download="cjeas-V2I2A6"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 97-110</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Ogunbadejo Hussain Kehinde and Zubair Aisha</h5>
                <div class="meta-span">The Effect of Human Capital on Economic Growth in Nigeria: A
                  Production Function Approach.</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A7.pdf" download="cjeas-V2I2A7"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 111-122</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Yusuf, Dansuma Lawal, Wada Emmanuel Ome & Atakpa Daniel Akoh
                </h5>
                <div class="meta-span">Psychoanalysis of Innovation in Nigeria Small and Medium
                  Enterprises: Implication for Economic Growth.</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A8.pdf" download="cjeas-V2I2A8"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 123-133</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Yusuf, Alih, Adama, F.U. and Obasa, O.A.</h5>
                <div class="meta-span">The Effect of Trade Liberalization on Economic Growth in
                  Nigeria (1986-2018).</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A9.pdf" download="cjeas-V2I2A9"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
          </ol>
        </div>
        <div class="col-md-6 col-sm-9">
          <ol class="appoint-timeline2 list-unstyled  ">
            <li>
              <span class="year"><b>Pages:</b> 134-145</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Kwasau, N.J. and Egwaikhide, C.I.</h5>
                <div class="meta-span">Manufacturing Productivity and Economic Growth in Nigeria:
                  Empirical Evidence form Kaldorian Approach.</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A10.pdf" download="cjeas-V2I2A10"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 146-159</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Ahmed Tafida Dagauda, Ph.D. and Nwosu G.C.</h5>
                <div class="meta-span">Procurement Process and Service Delivery in Federal
                  Government College, Kwali, F.C.T.</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A11.pdf" download="cjeas-V2I2A11"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 160-171</span>
              <div class="appoint-meta">
                <h5 class="meta-title"> Nwosa Philip Ifeakachukwu, Ogbuagu Matthew Ikechukwu, Fasina Oluwadamilola Tosin
                </h5>
                <div class="meta-span">Trade Openness and Country Size. </div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A12.pdf" download="cjeas-V2I2A12"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 172-186</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Salam Shuaib Mohammed </h5>
                <div class="meta-span">Public Procurement Act and Economic Performance: Evidence
                  from Nigeria. </div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A13.pdf" download="cjeas-V2I2A13"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 187-201</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Malachy, A Ugbaka (Ph.D), Solomon E.
                  Effiong and Joseph Usoh Umanah</h5>
                <div class="meta-span">Empirical Analysis of Agricultural Production and Economic
                  Growth in Nigeria. </div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A14.pdf" download="cjeas-V2I2A14"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 202-219</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Member Ahemen (Ph.D), Sunday
                  Edesiri Akiri (Ph.D) & Raymond Liambee Aor</h5>
                <div class="meta-span">Fiscal Federalism, Inclusive Growth and Agitations for
                  Restructuring in Nigeria.</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A15.pdf" download="cjeas-V2I2A15"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 220-242</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Iyaji Danjuma & Onotaniyohwo, Faith
                  Ogheneovo</h5>
                <div class="meta-span">Political Risk Indicators and Sectorial Foreign Direct Investment
                  Inflows in Nigeria.</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A16.pdf" download="cjeas-V2I2A16"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 243-256</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Yusuf, Dansuma Lawal, Sani Idris A. (Ph.D) &
                  Wada Emmanuel Ome</h5>
                <div class="meta-span">Analysis of entrepreneurship development and poverty reduction
                  in Nigeria.</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A17.pdf" download="cjeas-V2I2A17"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 257-270</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Olubode Oke (Ph.D), Orolugbagbe Olakunle &
                  OdugbemiTaiwo Hassan</h5>
                <div class="meta-span">The Effectiveness of Planning and Forecasting in Business
                  Organization in Federal Capital Territory, Abuja-Nigeria</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A18.pdf" download="cjeas-V2I2A18"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 271-291</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Melemi, Abatcha (PhD); Adeniji, Sesan Oluseyi (PhD); Odugbemi, Taiwo Hassan & Obaniyi, Fidelis Ademola</h5>
                <div class="meta-span">Analysis of the Impact of Oil Price Changes on Inflation in
                  Nigeria: Evidence from a Linear ARDL Model </div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue2/cjeas-V2I2A19.pdf" download="cjeas-V2I2A19"
                  target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section><!-- /.stage-halfbol -->
</body>
