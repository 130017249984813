import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  template: `
  <body class="auth-theme-rashmore page-team">
    <header class="sabbi-page-header page-header-lg">
        <div class="page-header-content conternt-center">
            <div class="header-title-block">
                <h1 class="page-title">Page Not Found</h1>
            </div>
        </div>
    </header>
    <div class="auth-breadcrumb-wrap">
        <div class="container">
            <ol class="breadcrumb sabbi-breadcrumb">
                <li><a routerLink="">Home</a></li>
                <li class="active">Page Not Found</li>
            </ol>
        </div>
    </div><!-- /.auth-breadcrumb-wrap -->
    <div class="container">
        <section class="ripepage-peak">
            <div class="row">
                <div class="col-sm-9">
                    <p class="page_piky-text">Sorry Page Not Found !!!</p>
                </div>
                <div class="col-sm-3">
                    <div class="btn_pdf_wrap"><a routerLink="" class="btn btn-gules tt_up">Return Home</a></div>
                </div>
            </div>
        </section><!-- /.ripepage-peak -->
    </div>
</body>
  `,
  styles: [
  ]
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
