<header class="sabbi-site-head">
    <nav class="navbar navbar-white navbar-kawsa navbar-fixed-top" role="navigation">
        <div class="container">
            <div class="navbar-header">
                <button aria-controls="navbar" aria-expanded="false" class="navbar-toggle collapsed"
                    data-target="#navbar" data-toggle="collapse" type="button"><span class="sr-only">Toggle
                        navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span
                        class="icon-bar"></span></button>
                <a class="navbar-brand" href="index.html"><img alt="" class="img-responsive"
                        src="assets/img/site-logo.png"></a>
            </div>
            <div class="navbar-collapse collapse sabbi-navbar-collapse  navbar-nav-hov_underline" id="navbar">

                <div class="nav-btn-wrap">
                    <h4 class="stage-title text-center" style="color: #DD4456;">A Publication Of The Department Of
                        Economics, Faculty Of Social Sciences<br />Prince Abubakar Audu University (PAAU), ‎Anyigba, Kogi
                        State, Nigeria.</h4>
                </div>

                <ul class="nav navbar-nav navbar-right" id="menu-main-nav">
                    <li routerLinkActive="active"><!-- -->
                        <a routerLink="home" title="Home">Home</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="about" title="About Us">About Us</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="editorialboard" title="Editorial Board">Editorial Board</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="currentissue" title="Current Issue">Current Issue</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="archives" title="Archives">Archives</a>
                    </li>
                    <li routerLinkActive="active">
                      <a routerLink="submitarticle" title="Submit Article">Submit Article</a>
                  </li>
                    <li class="dropdown">
                        <a class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" href="#"
                            title="Events" data-animations="zoomIn">Contact Us<span class="caret"></span></a>
                        <ul class=" dropdown-menu" role="menu">
                            <li>
                                <a href="" title="Contact Page">Contact Page</a>
                            </li>
                            <li>
                                <a href="" title="Submit Advert">Submit Advert</a>
                            </li>
                            <li>
                                <a href="" title="Subscribe">Subscribe</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a routerLink="" title="Member Login">Member Login</a>
                    </li>

                </ul>
            </div>
        </div>
    </nav><!-- /.navbar -->
</header><!-- /.sabbi-site-head -->
