<footer class="section-footer mt_25">
  <div class="container">
    <div class="row">
      <div class="col-sm-3">
        <div class="footer-site-info">
          <header>
            <a class="footer-brand" href="index.html"><img alt="" class="img-responsive"
                src="assets/img/site-logo.png"></a>
          </header>
          <address>
            <h3 class="entry-title">Address</h3>
            <div class="address-entry">
              <p class="__adtext">Department of Economics,</p>
              <p class="__ad-num">Prince Abubakar Audu University,</p>
              <p class="__ad-num">P.M.B. 1008,</p>
              <p class="__ad-num">Anyigba-Nigeria</p>
            </div>
          </address>
          <footer class="contact-info">
            <h3 class="entry-title">Contact Information</h3>
            <p class="__ci_num">Call: <span>+234 806 2065 126 | +234 806 5012 286</span></p>
            <p class="__ci_num">Email: <span>cjeasksu@gmail.com</span></p>
          </footer>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="row">
          <div class="col-sm-5 col-sm-offset-2">
            <section class="widget widget_sec">
              <div class="widget-main">
                <h2 class="widget-title">Research Scope</h2>
                <ul class="list list-unstyled list-footer-nav">
                  <li>Economics</li>
                  <li>Political Science</li>
                  <li>Sociology</li>
                  <li>Business Management</li>
                  <li>Banking and Finance</li>
                  <li>Agricultural Economics</li>
                </ul>
              </div>
            </section>
          </div>
          <div class="col-sm-5">
            <section class="widget widget_sec">
              <div class="widget-main">
                <h2 class="widget-title">Site Map</h2>
                <ul class="list list-unstyled list-footer-nav">
                  <li><a routerLink="">Home</a></li>
                  <li><a routerLink="about">About Us</a></li>
                  <li><a routerLink="editorialboard">Editorial Board</a></li>
                  <li><a routerLink="currentissue">Current Issue</a></li>
                  <li><a routerLink="submitarticle">Submit Article</a></li>
                  <li><a routerLink="archives">Archive</a></li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="footer-site-info">
          <address>
            <h3 class="entry-title">Latest Events</h3>
            <ul class="event-list list-unstyled">
              <li>
                <a routerLink="currentissue">
                  <h4 class="title">Call For Paper For Volume 3, Issue 2</h4>
                  <div class="date">SUBMISSION DEADELINE: 30th July 2020.</div>
                  <div class="date">PUBLICATION DATE: August 2020.</div>
                </a>
              </li>
            </ul>
          </address>
        </div>
      </div>
    </div>
  </div>
  <div id="site-footer-bar" class="footer-bar mt_30">
    <div class="container">
      <div class="row">
        <div class="col-sm-8">
          <div class="widget_black_studio_tinymce" id="black-studio-tinymce-4">
            <div class="copyright">Copyright &copy 2020 - <a routerLink="">CONFLUENCE JOURNAL OF ECONOMICS AND
                ALLIED SCIENCES (CJEAS)</a></div>
          </div>
        </div>
        <div class="col-sm-4 ">
          <div class="powredby">Developed and Managed by: <a href="">LAZNABTEC Nig. Ltd.</a></div>
        </div>
      </div>
    </div>
  </div>
</footer><!-- /.section-footer -->
