<body class="auth-theme-rashmore page-team">
  <header class="sabbi-page-header page-header-lg">
      <div class="page-header-content conternt-center">
          <div class="header-title-block">
              <h1 class="page-title">Submit Article</h1>
          </div>
      </div>
  </header>
  <div class="auth-breadcrumb-wrap">
      <div class="container">
          <ol class="breadcrumb sabbi-breadcrumb">
              <li><a routerLink="">Home</a></li>
              <li class="active">Submit Article</li>
          </ol>
      </div>
  </div><!-- /.auth-breadcrumb-wrap -->
  <main class="sabbi-page-wrap">
      <section class="ripepage-peak">
          <div class="container">
              <div class="row">
                  <div class="col-sm-3 col-md-4">
                      <div class="page_piky-title">
                          <h2 class="page-title font-md lil-line">ARTICLE SUBMISSION PROCEDURE OF THE CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE
                              (CJEAS)</h2>
                      </div>
                  </div>
                  <div class="col-sm-8 col-sm-offset-1 col-md-7 col-md-offset-1">
                      <h4 class="page_piky-text stage-title" style="color: #DD4456;">Guidelines for submission of Articles</h4>
                  </div>
                  <div class="col-sm-8">
                    <article class="profile-glimps">
                        <div class="stage-content-biog">
                            <ul class="list-unstyled entry-meta-list list-style-round_item">
                                <li>CJEAS accepts articles submitted in MS Word only and written in English. Citations and
                                    referencing should be APA compliant. </li>
                                <li>Manuscripts must be typed on <b>A4 paper, Font Size of 12, 1.5 Line Spacing and One Inch
                                        (2.5cm) for top, bottom and both side of the page.</b> All characters must be in
                                    <b>TIMES
                                        NEW ROMAN.</b> Paper should not be more than <b>18 pages</b> including references. All
                                    equations should be prepared using Microsoft Equation Editor or Math type.</li>
                                <li>Articles should appear in the following order: Title of Article; Name(s) of Author(s),
                                    with the corresponding contact address (email and phone number); Institutional
                                    Affiliation(s); Abstract; Keywords; JEL Classification code; Introduction; Literature
                                    Review; Methodology; Results and Discussion; Conclusion/Recommendation; References; and
                                    Appendix (if any). Abstract should not be more than <b>250 words.</b></li>
                                <li>Papers submitted after the submission deadline of an issue are automatically rolled over
                                    to the following issue. </li>
                                <li>All tables and figures should be appropriately numbered, titled, bolded and placed on
                                    top left of the tables or figures. In describing or explaining tables or figures,
                                    authors should rather use “The results of OLS tests are presented in table 1”</li>
                                <li>All manuscript should be sent to the following <b>emails: cjeasksu@gmail.com & copy
                                        alehie.ks@ksu.edu.ng</b></li>
                            </ul>
                                <h3 class="stage-title">Submission and Assessment/Publication Fee</h3>
                                <p class="stage-text">Payment is subdivided into peer review fee of <b> NGN 5,000.00 (Five
                                        Thousand
                                        Naira only)</b> and Publication Fee of <b>NGN 20,000.00 (Twenty Thousand Naira
                                        only).</b> The peer
                                    review fee is paid and evidence submitted with the manuscript while the publication fee
                                    is paid upon the acceptance of the manuscript. Submission of corrected manuscript should
                                    be accompanied with evidence of payment of publication fee. Authors are entitled to <b>ONE
                                        (1) Hard Copy of the Journal.</b> Extra copy attracts <b>NGN 3,000.00.</b></p>
                                <p>Author(s) can make direct cash payment or through electronic fund transfer to the detalis
                                    below;</p>
                                <h3 class="stage-title">Payment Account Details</h3>
                                <ul class="list-unstyled entry-meta-list list-style-round_item">
                                    <li><b>ACCOUNT NAME:</b> CONFLUENCE JOURNAL OF ECONOMICS & ALLIED SCIENCES (CJEAS)</li>
                                    <li><b>ACCOUNT NUMBER: </b>1022876347 </li>
                                    <li><b>BANK: </b>UNITED BANK FOR AFRICA PLC</li>
                                    <li><b>ACCOUNT TYPE: </b>CURRENT</li>
                                </ul>
                        </div><!-- /.stage-content-biog -->
                    </article>
                </div><!-- #main -->
              </div>
          </div>
      </section>
  </main>
</body>
