import { SubmitarticleComponent } from './submitarticle/submitarticle.component';
import { V2i1Component } from './v2i1/v2i1.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { V2Component } from './v2/v2.component';
import { V3Component } from './v3/v3.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { from } from 'rxjs';
import {HomeComponent} from './home/home.component'
import {AboutComponent} from './about/about.component'
import {EditorialboardComponent} from './editorialboard/editorialboard.component'
import {CurrentissueComponent} from './currentissue/currentissue.component'
import { ArchivesComponent } from './archives/archives.component';
import { V2i2Component } from './v2i2/v2i2.component';
import { V3i2Component } from './v3i2/v3i2.component';
import { V3i3Component } from './v3i3/v3i3.component';
import { SuleabubakarComponent } from './suleabubakar/suleabubakar.component';



const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: {title: 'Home | CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE (CJEAS)'}
  },
  {
    path: 'about',
    component: AboutComponent,
    data: {title: 'About | CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE (CJEAS)'}
  },
  {
    path: 'editorialboard',
    component: EditorialboardComponent,
    data: {title: 'Editorial Board | CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE (CJEAS)'},
    // children: [{path: 'saniidrisahmed', component: SaniidrisahmedComponent, data: {title: 'Sani Idris Ahmed'}}]
  },
  {
    path: 'currentissue',
    component: CurrentissueComponent,
    data: {title: 'Current Issue | CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE (CJEAS)'}
  },
  {
    path: 'archives',
    component: ArchivesComponent,
    data: {title: 'Archives | CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE (CJEAS)'}
  },
  {
    path: 'submitarticle',
    component: SubmitarticleComponent,
    data: {title: 'Submit Article | CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE (CJEAS)'}
  },
  {
    path: 'v2',
    component: V2Component,
    data: {title: 'Volume 2 | CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE (CJEAS)'}
  },
  {
    path: 'v3',
    component: V3Component,
    data: {title: 'Volume 3 | CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE (CJEAS)'}
  },
  {
    path: 'v2i1',
    component: V2i1Component,
    data: {title: 'Volume 2, Issue 1 | CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE (CJEAS)'}
  },
  {
    path: 'v2i2',
    component: V2i2Component,
    data: {title: 'Volume 2, Issue 2 | CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE (CJEAS)'}
  },
  {
    path: 'v3i2',
    component: V3i2Component,
    data: {title: 'Volume 3, Issue 2 | CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE (CJEAS)'}
  },
  {
    path: 'v3i3',
    component: V3i3Component,
    data: {title: 'Volume 3, Issue 3 | CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE (CJEAS)'}
  },
  {
    path: 'suleabubakar',
    component: SuleabubakarComponent,
    data: {title: 'Sule Abubakar Profile Page | CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE (CJEAS)'}
  },
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {title: 'Page Not Found'}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
