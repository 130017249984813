<body class="auth-theme-rashmore page-team">
    <header class="sabbi-page-header page-header-lg">
        <div class="page-header-content conternt-center">
            <div class="header-title-block">
                <h1 class="page-title">Journal Archive</h1>
            </div>
        </div>
    </header>
    <div class="auth-breadcrumb-wrap">
        <div class="container">
            <ol class="breadcrumb sabbi-breadcrumb">
                <li><a routerLink="">Home</a></li>
                <li class="active">Journal Archive</li>
            </ol>
        </div>
    </div><!-- /.auth-breadcrumb-wrap -->
    <main class="sabbi-page-wrap">
        <section class="ripepage-peak">
            <div class="container">
                <div class="row">
                    <div class="col-sm-3 col-md-4">
                        <div class="page_piky-title">
                            <h2 class="page-title font-md lil-line">CONFLUENCE JOURNAL OF ECONOMICS AND ALLIED SCIENCE
                                (CJEAS)</h2>
                        </div>
                    </div>
                    <div class="col-sm-8 col-sm-offset-1 col-md-7 col-md-offset-1">
                        <h4 class="page_piky-text stage-title" style="color: #DD4456;">A Publication Of The Department
                            Of Economics, Faculty Of Social Sciences, Prince Abubakar Audu University (PAAU), ‎Anyigba,
                            Kogi State, Nigeria.</h4>
                        <ul class="list-unstyled entry-meta-list list-style-round_item">
                            <li><b>Total Volumes:</b> 3</li>
                            <li><b>Total Issues:</b> 37</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="book-timeline-section">
            <div class="container">
                <div class="sabbi-book_timeline-segment">
                    <ul class="sabbi-book_timeline list-unstyled">
                        <li>
                            <b><span class="year">2020</span></b>
                            <ul class="list-unstyled">
                              <a routerLink="../v3">
                                    <li>
                                        <figure>
                                            <img src="assets/img/timeline/v3.png" alt="published books"
                                                class="img-responsive">
                                        </figure>
                                        <div class="book-list-meta">
                                            <h3 class="book-list-title">Volume 3 </h3>
                                            <div class="book-list-brand"><em>PUBLICATION DATE: August 2020.</em>
                                            </div>
                                            <a routerLink="../v3" class="btn btn-action btn-gules">View Journal ~</a>
                                        </div>
                                    </li>
                                </a>
                            </ul>
                            <a href="#" class="btn btn-unsolemn btn-expand" data-text="Explore More"></a>
                        </li>
                        <li>
                            <b><span class="year">2019</span></b>
                            <ul class="list-unstyled">
                                <a routerLink="../v2">
                                    <li>
                                        <figure>
                                            <img src="assets/img/timeline/v2.png" alt="published books"
                                                class="img-responsive">
                                        </figure>
                                        <div class="book-list-meta">
                                            <h3 class="book-list-title">Volume 2</h3>
                                            <div class="book-list-brand"><em>PUBLICATION DATE: December 2019.</em>
                                            </div>
                                            <a routerLink="../v2" class="btn btn-action btn-gules">View Journal ~</a>
                                        </div>
                                    </li>
                                </a>
                            </ul>
                            <a href="#" class="btn btn-unsolemn btn-expand" data-text="View More Books"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </section><!-- /.book-timeline -->
    </main>
</body>
