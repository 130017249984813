<body class="auth-theme-rashmore page-professor">
  <header class="sabbi-page-header page-header-lg">
    <div class="page-header-content conternt-center"></div>
  </header><!-- ./sabbi-page-header  -->
  <div class="container">
    <div class="row">
      <div class="col-sm-4 col-md-4 pull-up-240">
        <img class="img-bit-round" alt="" src="assets/img/sabbi-post/v2-i1.png">
        <div class="sp-blank-20"></div>
        <address class="addr_future_memb">
          <h4 class="entry-title">Volume 2 Issue 1</h4>
          <div class="address-entry">
            <p class="__adtext">December 2019</p>
            <p class="__ad-num"><b>Pages: </b>1-297</p>
            <p class="__ad-num"><b>Number of Articles:</b> 18</p>
          </div>
          <footer class="contact-info">
            <h4 class="entry-title">Download Format</h4>
            <p class="__ci_num"><strong>Download Full Issue (PDF): </strong>
              <span>
                <a href="" download="">
                  Download <i class="fas fa-file-pdf-o" style="font-size:38px;color:red"></i>
                </a>
            </span>
            </p>
            <!-- <p class="__ci_num"><strong>Email:</strong> <span>cjeasksu@gmail.com</span></p> -->
          </footer>
        </address>
      </div>
      <div class="col-sm-8">
        <article class="profile-glimps">
          <div class="stage-content-biog">
            <h3 class="stage-title">Confluence Journal of Economics and Allied Sciences Volume 2 Issue 1</h3>
            <u><b>BOARD OF EDITORS:</b></u><br />
            <b>EDITOR-IN-CHIEF: </b>DR IDOKO, C.U., <b>EDITOR: </b>DR MOHAMMED, IBRAHIM .D.,
            <b>BUSINESS MANAGER: </b>DR AWE, E.O., <b>SECRETARY: </b>SULE ABUBAKAR<br /><br />
            <u><b>CONSULTING EDITORS:</b></u><br />
            <b>Prof. Onah, F.E. -</b> University of Nigeria, Nsukka,<br />
            <b>Prof. Ibitoye, S.J. -</b> Kogi State University, Anyigba,<br />
            <b>Prof. Wafure, G.O. -</b> University of Abuja,<br />
            <b>Prof. (Mrs.) Tijjani Jibril Binta -</b> Bayero University, Kano,<br />
            <b>Dr Adofu, Ilemona -</b> Federal University, Lafia,<br />
            <b>Dr (Mrs.) Doki, Naomi Onyeje -</b> Benue State University, Makurdi.<br />

            <br /><u><b>NOTES TO INTENDING CONTRIBUTORS:</b></u><br />
            The Confluence Journal of Economics and Allied Sciences publish papers related to the economy and
            society in Africa bi-annually (July and December). The Journal focus on policy oriented papers
            including technical papers from economics, socio-political, business, agricultural economics, and
            historical issues, financial or related field that border on economics and development in Nigeria, Africa
            and the world at large. <a routerLink="../about">Read More</a><br />
          </div><!-- /.stage-content-biog -->
        </article>
      </div><!-- #main -->
    </div>
  </div>
  <section class="sabbi-section stage-halfbol stage-full_width">
    <div class="container">
      <h4 class="gimps-title">TABLE OF CONTENT / ARTICLES</h4>
      <div class="row">
        <div class="col-md-6 col-sm-9">
          <ol class="appoint-timeline2  list-unstyled  ">
            <li>
              <span class="year"><b>Pages:</b> 1-11</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Idoko, C.U. (Ph.D)</h5>
                <div class="meta-span">Financial Liberalisation in the Globalised Economy: The Nigerian Experience.
                </div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A1.pdf" download="cjeas-V2I1A1" target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 12-25</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Mrs. Doki, N. O. (Ph.D) & Tyokohol, M.Y</h5>
                <div class="meta-span">Export Diversification and Economic Growth in Nigeria: Manufacturing and
                  Service Exports as Options. </div>
                  <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A2.pdf" download="cjeas-V2I1A2" target="_blank">
                    Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                  </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 26-40</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Taofik Mohammed Ibrahim</h5>
                <div class="meta-span">The Relationship between Capital Flows and Current Account Balance in Nigeria. </div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A3.pdf" download="cjeas-V2I1A3" target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 41-60</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Silas Idoko Abu</h5>
                <div class="meta-span">Poverty and Old Age in Nigeria.</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A4.pdf" download="cjeas-V2I1A4" target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 61-75</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Buba M. Soba</h5>
                <div class="meta-span">An Empirical Evidence of Determinants of Investment Behaviour in Nigeria. </div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A5.pdf" download="cjeas-V2I1A5" target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 76-84</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Obomeghie, M. A. and Idris, Abubakar</h5>
                <div class="meta-span">Review of Government appropriation to social development factors (SDF)
                  and its impact on human capital development. </div>
                  <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A6.pdf" download="cjeas-V2I1A6" target="_blank">
                    Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                  </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 85-103</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Olanrenwaju Makinde Hassan (Ph.D), John Abu & Josephine O. Adayi</h5>
                <div class="meta-span">Financial intermediation and agricultural sector output in Nigeria.</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A7.pdf" download="cjeas-V2I1A7" target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 104-118</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Mohammed, Ibrahim Danlami (Ph.D)</h5>
                <div class="meta-span">Monetary policy and price level stability in Nigeria.</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A8.pdf" download="cjeas-V2I1A8" target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 119-136</span>
              <div class="appoint-meta">
                <h5 class="meta-title">IniengerChia Christopher & Donald Mutetwa</h5>
                <div class="meta-span">Bureaucracy and the Performance of Public Enterprises in Nigeria:
                  A Study of Nigerian Ports Authority (NPA).</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A9.pdf" download="cjeas-V2I1A9" target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
          </ol>
        </div>
        <div class="col-md-6 col-sm-9">
          <ol class="appoint-timeline2 list-unstyled  ">
            <li>
              <span class="year"><b>Pages:</b> 137-157</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Keyi M.D., Saheed Z.S. & AlexanderA.A</h5>
                <div class="meta-span">Impact of Government Fiscal Expenditure on Economic Growth in Nigeria.</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A10.pdf" download="cjeas-V2I1A10" target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 158-176</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Eche Nwachukwu Austine, Anaeto Abigail C. and
                  Haruna Ibrahim Babagana </h5>
                <div class="meta-span">An ARDL Approach to Fiscal Policy Effect on Unemployment in Nigeria. </div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A11.pdf" download="cjeas-V2I1A11" target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 177-189</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Freeman Aye-Agele and Vivian Inyang Patrick</h5>
                <div class="meta-span">Indigenous Technology and Petroleum Sector Development in Nigeria</div>
                <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A12.pdf" download="cjeas-V2I1A12" target="_blank">
                  Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 190-210</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Alfa, Yakubu, Buari, AA. and Lamidi, YS.</h5>
                <div class="meta-span">Monetary Policy Instruments and Inflation Targeting in Nigeria:
                  Evidence from Autoregressive Distributed Lag Model.</div>
                  <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A13.pdf" download="cjeas-V2I1A13" target="_blank">
                    Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                  </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 211-221</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Aghanenu Augustine Sunday (Ph.D), Uffie Edison James</h5>
                <div class="meta-span">Education and Economic Growth in West Africa countries:
                  A Panel Estimation. </div>
                  <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A14.pdf" download="cjeas-V2I1A14" target="_blank">
                    Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                  </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 222-240</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Abubakar, Idris Ayokanmi; Onibiyo, Ezekiel Rotimi; Danbatta,
                  Atiku Saleh and Abdulahi Mohammed Itopa</h5>
                <div class="meta-span">Public Debt and Credit to the Private Sector in Nigeria:
                  An Impulse Response Function Approach.</div>
                  <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A15.pdf" download="cjeas-V2I1A15" target="_blank">
                    Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                  </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 241-256</span>
              <div class="appoint-meta">
                <h5 class="meta-title">ISA, Nurudden Mohammed (Ph.D), SALAKO, Emmanuel B.
                  and AWE, Emmanuel O. (Ph.D)</h5>
                <div class="meta-span">Empirical Analysis of the Relationship between Exchange
                  Rate Fluctuations and Foreign Direct Investment in Nigeria: 1995-2017.</div>
                  <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A16.pdf" download="cjeas-V2I1A16" target="_blank">
                    Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                  </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 257-271</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Okwori J. (Ph.D) and Okwori, A.</h5>
                <div class="meta-span">The Potential of Remittances as an Alternative Revenue Channel for
                  Unemployment Reduction in Nigeria.</div>
                  <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A17.pdf" download="cjeas-V2I1A17" target="_blank">
                    Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                  </a>
              </div>
            </li>
            <li>
              <span class="year"><b>Pages:</b> 272-297</span>
              <div class="appoint-meta">
                <h5 class="meta-title">Onotaniyohwo, Faith Ogheneovo and Iyaji, Danjuma</h5>
                <div class="meta-span">Impact of Population Health Status on Productivity Outcomes in
                  sub-Saharan African Countries. </div>
                  <a href="https://cjeas.org/assets/jornals/volume2/issue1/cjeas-V2I1A18.pdf" download="cjeas-V2I1A18" target="_blank">
                    Download (pdf) <i class="fas fa-file-pdf-o" style="font-size:20px;color:red"></i>
                  </a>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section><!-- /.stage-halfbol -->
</body>
