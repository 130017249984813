<body class="auth-theme-rashmore page-team">
  <header class="sabbi-page-header page-header-lg">
    <div class="page-header-content conternt-center">
      <div class="header-title-block">
        <h1 class="page-title">Journal Volume 2</h1>
      </div>
    </div>
  </header>
  <div class="auth-breadcrumb-wrap">
    <div class="container">
      <ol class="breadcrumb sabbi-breadcrumb">
        <li><a routerLink="">Home</a></li>
        <li><a routerLink="../archives">Archives</a></li>
        <li class="active">Journal Volume 2</li>
      </ol>
    </div>
  </div><!-- /.auth-breadcrumb-wrap -->

  <section class="deff-timeline-section">
    <div class="container">
      <div class="auth-deff_timeline_timeline-segment">
        <ul class="auth-deff_timeline list-unstyled">
          <li>
            <div class="time-span">
              <div class="time-year">2019</div>
              <div class="time-month">December</div>
            </div>
            <div class="timeline-meta">
              <h3 class="staff-title">Issue 2</h3>
              <div class="__time"><b>Pages: </b>1-291</div>
              <!-- <div class="__time"><b>Pages: </b>298-588</div> -->
              <div class="__loc"><span>Number of Articles:</span> 19</div>
              <p class="meta-text"><b>EDITOR-IN-CHIEF: </b>DR IDOKO, C.U., <b>EDITOR: </b>DR MOHAMMED, IBRAHIM .D.,
                <b>BUSINESS MANAGER: </b>DR AWE, E.O., <b>SECRETARY: </b>SULE ABUBAKAR ...</p>
              <a routerLink="../v2i2" class="btn btn-action btn-gules">View Issue ~</a>
            </div>
          </li><!-- /timeline-item -->
          <li>
            <div class="time-span">
              <div class="time-year">2019</div>
              <div class="time-month">December</div>
            </div>
            <div class="timeline-meta">
              <h3 class="staff-title">Issue 1</h3>
              <div class="__time"><b>Pages: </b>1-297</div>
              <div class="__loc"><span>Number of Articles:</span> 18</div>
              <p class="meta-text"><b>EDITOR-IN-CHIEF: </b>DR IDOKO, C.U., <b>EDITOR: </b>DR MOHAMMED, IBRAHIM .D.,
                <b>BUSINESS MANAGER: </b>DR AWE, E.O., <b>SECRETARY: </b>SULE ABUBAKAR ...</p>
              <a routerLink="../v2i1" class="btn btn-action btn-gules">View Issue ~</a>
            </div>
          </li><!-- /timeline-item -->
        </ul><!-- /.auth-deff_timeline -->
      </div>
    </div>
  </section>


</body>
