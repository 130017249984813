<body class="auth-theme-rashmore page-professor">
  <header class="sabbi-page-header page-header-lg">
    <div class="page-header-content conternt-center"></div>
  </header><!-- ./sabbi-page-header  -->
  <div class="container">
    <div class="row">
      <div class="col-sm-4 col-md-4 pull-up-240">
        <img class="img-bit-round" alt="" src="../assets/img/pprofile/sulea.png">
        <div class="sp-blank-20"></div>
        <address class="addr_future_memb">
          <h4 class="entry-title">Sule Abubakar</h4>
          <div class="address-entry">
            <p class="__adtext"><strong>Designation:</strong> Secretary</p>
            <p class="__ad-num"><strong>Phone Number 1:</strong> <a href="tel:08039689256"> (+234) 803 9689 256</a></p>
            <p class="__ad-num"><strong>Phone Number 2:</strong> <a href="tel:07059364080"> (+234) 705 9364 080</a></p>
            <p class="__ad-num"><strong>Email: </strong> <a href="mailto:sule.a@ksu.edu.ng">sule.a@ksu.edu.ng</a></p>
          </div>
          <footer class="contact-info">
            <h4 class="entry-title">Other Profiles</h4>
            <p class="__ci_num"><strong>Twitter: </strong> <a href="https://twitter.com/sulea07"
                target="_blank">twitter.com/sulea07 <sup><i class="fas fa-external-link-alt"></i></sup></a></p>
          </footer>
        </address>
      </div>
      <div class="col-sm-8">
        <article class="profile-glimps">
          <div class="stage-content-biog">
            <h3 class="stage-title">About</h3>
            <hr />
            <p class="stage-text">
              <b>Born:</b> Abubakar, 7th March, Kogi State.<br />
              <b>Nationality:</b> Nigeria<br />

          </div><!-- /.stage-content-biog -->
          <div class="stage-content-biog">
            <h3 class="stage-title">Education</h3>
            <hr />
              <!-- <b>Born:</b> Abubakar, 7th March, Kogi State.<br /> -->

          </div><!-- /.stage-content-biog -->
          <div class="stage-content-biog">
            <h3 class="stage-title">Certificate / Awards</h3>
            <hr />
              <!-- <b>Born:</b> Abubakar, 7th March, Kogi State.<br /> -->

          </div><!-- /.stage-content-biog -->
          <div class="stage-content-biog">
            <h3 class="stage-title">Publications / Work</h3>
            <hr />
            <ul class="list-unstyled entry-meta-list list-style-round_item">
              <li>Sule, A. (2020) Institutional Quality and Economic Growth in Nigeria. African Journal of
                Economic Review, VIII(I), 48-64</li>
          </ul>
          </div><!-- /.stage-content-biog -->
        </article>
      </div><!-- #main -->
    </div>
  </div>
</body>
