// import { environment } from './../environments/environment.prod';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { environment } from '../environments/environment';

// import * as firebase from 'firebase/app';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { EditorialboardComponent } from './editorialboard/editorialboard.component';
import { CurrentissueComponent } from './currentissue/currentissue.component';
import { ArchivesComponent } from './archives/archives.component';
import { V2Component } from './v2/v2.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { V2i1Component } from './v2i1/v2i1.component';
import { V2i2Component } from './v2i2/v2i2.component';
import { SuleabubakarComponent } from './suleabubakar/suleabubakar.component';
import { SubmitarticleComponent } from './submitarticle/submitarticle.component';
import { V3i2Component } from './v3i2/v3i2.component';
import { V3Component } from './v3/v3.component';
import { V3i3Component } from './v3i3/v3i3.component';


// firebase.initializeApp(environment.firebaseConfig);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    EditorialboardComponent,
    CurrentissueComponent,
    ArchivesComponent,
    V2Component,
    PageNotFoundComponent,
    V2i1Component,
    V2i2Component,
    SuleabubakarComponent,
    SubmitarticleComponent,
    V3i2Component,
    V3Component,
    V3i3Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
