import { Component, OnInit } from '@angular/core'; //, AfterViewInit
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

// import * as firebase from 'firebase/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit { //, AfterViewInit
  title = 'cjeas';

  constructor(
    private titleservice: Title,
    private router: Router,
    private activePage: ActivatedRoute
  ) { }

  ngOnInit() {
    this.changeTitle();
  }

  // ngAfterViewInit(){
  //   firebase.analytics().logEvent('eventname', {
  //     'firsttimeuser': true,
  //     'username': 'jubril'
  //   })
  // }

  changeTitle() {
    this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationEnd:
          let child = this.activePage.firstChild;

          while (child.firstChild) {
            child = child.firstChild;
          }
          this.titleservice.setTitle(child.snapshot.data.title);
          break;

        default:
          break;
      }
    });
  }
}
